export default {
  69061600: {
    rut: 69061600,
    nombre: 'ALGARROBO',
    codigo_subdere: 5602,
    region: 5,
  },
  69073200: {
    rut: 69073200,
    nombre: 'ALHUE',
    codigo_subdere: 13502,
    region: 13,
  },
  69264600: {
    rut: 69264600,
    nombre: 'ALTO BIOBIO',
    codigo_subdere: 8314,
    region: 8,
  },
  69251900: {
    rut: 69251900,
    nombre: 'ALTO DEL CARMEN',
    codigo_subdere: 3302,
    region: 3,
  },
  69265100: {
    rut: 69265100,
    nombre: 'ALTO HOSPICIO',
    codigo_subdere: 1107,
    region: 1,
  },
  69230100: {
    rut: 69230100,
    nombre: 'ANCUD',
    codigo_subdere: 10202,
    region: 10,
  },
  69040400: {
    rut: 69040400,
    nombre: 'ANDACOLLO',
    codigo_subdere: 4103,
    region: 4,
  },
  69180100: {
    rut: 69180100,
    nombre: 'ANGOL',
    codigo_subdere: 9201,
    region: 9,
  },
  69020300: {
    rut: 69020300,
    nombre: 'ANTOFAGASTA',
    codigo_subdere: 2101,
    region: 2,
  },
  69250900: {
    rut: 69250900,
    nombre: 'ANTUCO',
    codigo_subdere: 8302,
    region: 8,
  },
  69160100: {
    rut: 69160100,
    nombre: 'ARAUCO',
    codigo_subdere: 8202,
    region: 8,
  },
  69010100: {
    rut: 69010100,
    nombre: 'ARICA',
    codigo_subdere: 15101,
    region: 15,
  },
  69240100: {
    rut: 69240100,
    nombre: 'AYSEN',
    codigo_subdere: 11201,
    region: 11,
  },
  69072500: {
    rut: 69072500,
    nombre: 'BUIN',
    codigo_subdere: 13402,
    region: 13,
  },
  69141200: {
    rut: 69141200,
    nombre: 'BULNES',
    codigo_subdere: 16102,
    region: 16,
  },
  69050200: {
    rut: 69050200,
    nombre: 'CABILDO',
    codigo_subdere: 5402,
    region: 5,
  },
  69254400: {
    rut: 69254400,
    nombre: 'CABO DE HORNOS',
    codigo_subdere: 12201,
    region: 12,
  },
  69151000: {
    rut: 69151000,
    nombre: 'CABRERO',
    codigo_subdere: 8303,
    region: 8,
  },
  69020200: {
    rut: 69020200,
    nombre: 'CALAMA',
    codigo_subdere: 2201,
    region: 2,
  },
  69220600: {
    rut: 69220600,
    nombre: 'CALBUCO',
    codigo_subdere: 10102,
    region: 10,
  },
  69030300: {
    rut: 69030300,
    nombre: 'CALDERA',
    codigo_subdere: 3102,
    region: 3,
  },
  69072800: {
    rut: 69072800,
    nombre: 'CALERA DE TANGO',
    codigo_subdere: 13403,
    region: 13,
  },
  69051200: {
    rut: 69051200,
    nombre: 'CALLE LARGA',
    codigo_subdere: 5302,
    region: 5,
  },
  69251000: {
    rut: 69251000,
    nombre: 'CAMARONES',
    codigo_subdere: 15102,
    region: 15,
  },
  69251100: {
    rut: 69251100,
    nombre: 'CAMINA',
    codigo_subdere: 1402,
    region: 1,
  },
  69041300: {
    rut: 69041300,
    nombre: 'CANELA',
    codigo_subdere: 4202,
    region: 4,
  },
  69160500: {
    rut: 69160500,
    nombre: 'CANETE',
    codigo_subdere: 8203,
    region: 8,
  },
  69190500: {
    rut: 69190500,
    nombre: 'CARAHUE',
    codigo_subdere: 9102,
    region: 9,
  },
  69073600: {
    rut: 69073600,
    nombre: 'CARTAGENA',
    codigo_subdere: 5603,
    region: 5,
  },
  69061400: {
    rut: 69061400,
    nombre: 'CASABLANCA',
    codigo_subdere: 5102,
    region: 5,
  },
  69230400: {
    rut: 69230400,
    nombre: 'CASTRO',
    codigo_subdere: 10201,
    region: 10,
  },
  69050900: {
    rut: 69050900,
    nombre: 'CATEMU',
    codigo_subdere: 5702,
    region: 5,
  },
  69120400: {
    rut: 69120400,
    nombre: 'CAUQUENES',
    codigo_subdere: 7201,
    region: 7,
  },
  69255000: {
    rut: 69255000,
    nombre: 'CERRILLOS',
    codigo_subdere: 13102,
    region: 13,
  },
  69254200: {
    rut: 69254200,
    nombre: 'CERRO NAVIA',
    codigo_subdere: 13103,
    region: 13,
  },
  69231100: {
    rut: 69231100,
    nombre: 'CHAITEN',
    codigo_subdere: 10401,
    region: 10,
  },
  69030100: {
    rut: 69030100,
    nombre: 'CHA\u00d1ARAL',
    codigo_subdere: 3201,
    region: 3,
  },
  69120300: {
    rut: 69120300,
    nombre: 'CHANCO',
    codigo_subdere: 7202,
    region: 7,
  },
  69090700: {
    rut: 69090700,
    nombre: 'CHEPICA',
    codigo_subdere: 6302,
    region: 6,
  },
  69264700: {
    rut: 69264700,
    nombre: 'CHIGUAYANTE',
    codigo_subdere: 8103,
    region: 8,
  },
  69240400: {
    rut: 69240400,
    nombre: 'CHILE CHICO',
    codigo_subdere: 11401,
    region: 11,
  },
  69140900: {
    rut: 69140900,
    nombre: 'CHILLAN',
    codigo_subdere: 16101,
    region: 16,
  },
  69266500: {
    rut: 69266500,
    nombre: 'CHILLAN VIEJO',
    codigo_subdere: 16103,
    region: 16,
  },
  69090300: {
    rut: 69090300,
    nombre: 'CHIMBARONGO',
    codigo_subdere: 6303,
    region: 6,
  },
  69265000: {
    rut: 69265000,
    nombre: 'CHOLCHOL',
    codigo_subdere: 9121,
    region: 9,
  },
  69230500: {
    rut: 69230500,
    nombre: 'CHONCHI',
    codigo_subdere: 10203,
    region: 10,
  },
  69240200: {
    rut: 69240200,
    nombre: 'CISNES',
    codigo_subdere: 11202,
    region: 11,
  },
  69140400: {
    rut: 69140400,
    nombre: 'COBQUECURA',
    codigo_subdere: 16202,
    region: 16,
  },
  69252000: {
    rut: 69252000,
    nombre: 'COCHAMO',
    codigo_subdere: 10103,
    region: 10,
  },
  69254500: {
    rut: 69254500,
    nombre: 'COCHRANE',
    codigo_subdere: 11301,
    region: 11,
  },
  69080400: {
    rut: 69080400,
    nombre: 'CODEGUA',
    codigo_subdere: 6102,
    region: 6,
  },
  69150200: {
    rut: 69150200,
    nombre: 'COELEMU',
    codigo_subdere: 16203,
    region: 16,
  },
  69141100: {
    rut: 69141100,
    nombre: 'COIHUECO',
    codigo_subdere: 16302,
    region: 16,
  },
  69081600: {
    rut: 69081600,
    nombre: 'COINCO',
    codigo_subdere: 6103,
    region: 6,
  },
  69130500: {
    rut: 69130500,
    nombre: 'COLBUN',
    codigo_subdere: 7402,
    region: 7,
  },
  69250400: {
    rut: 69250400,
    nombre: 'COLCHANE',
    codigo_subdere: 1403,
    region: 1,
  },
  69071500: {
    rut: 69071500,
    nombre: 'COLINA',
    codigo_subdere: 13301,
    region: 13,
  },
  69180500: {
    rut: 69180500,
    nombre: 'COLLIPULLI',
    codigo_subdere: 9202,
    region: 9,
  },
  69080700: {
    rut: 69080700,
    nombre: 'COLTAUCO',
    codigo_subdere: 6104,
    region: 6,
  },
  69041100: {
    rut: 69041100,
    nombre: 'COMBARBALA',
    codigo_subdere: 4302,
    region: 4,
  },
  69150400: {
    rut: 69150400,
    nombre: 'CONCEPCION',
    codigo_subdere: 8101,
    region: 8,
  },
  69070200: {
    rut: 69070200,
    nombre: 'CONCHALI',
    codigo_subdere: 13104,
    region: 13,
  },
  73568600: {
    rut: 73568600,
    nombre: 'CONCON',
    codigo_subdere: 5103,
    region: 5,
  },
  69120100: {
    rut: 69120100,
    nombre: 'CONSTITUCION',
    codigo_subdere: 7102,
    region: 7,
  },
  69160600: {
    rut: 69160600,
    nombre: 'CONTULMO',
    codigo_subdere: 8204,
    region: 8,
  },
  69030200: {
    rut: 69030200,
    nombre: 'COPIAPO',
    codigo_subdere: 3101,
    region: 3,
  },
  69040300: {
    rut: 69040300,
    nombre: 'COQUIMBO',
    codigo_subdere: 4102,
    region: 4,
  },
  69151200: {
    rut: 69151200,
    nombre: 'CORONEL',
    codigo_subdere: 8102,
    region: 8,
  },
  69200200: {
    rut: 69200200,
    nombre: 'CORRAL',
    codigo_subdere: 14102,
    region: 14,
  },
  69240300: {
    rut: 69240300,
    nombre: 'COYHAIQUE',
    codigo_subdere: 11101,
    region: 11,
  },
  69191000: {
    rut: 69191000,
    nombre: 'CUNCO',
    codigo_subdere: 9103,
    region: 9,
  },
  69181000: {
    rut: 69181000,
    nombre: 'CURACAUTIN',
    codigo_subdere: 9203,
    region: 9,
  },
  69073900: {
    rut: 69073900,
    nombre: 'CURACAVI',
    codigo_subdere: 13503,
    region: 13,
  },
  69231000: {
    rut: 69231000,
    nombre: 'CURACO DE VELEZ',
    codigo_subdere: 10204,
    region: 10,
  },
  69160200: {
    rut: 69160200,
    nombre: 'CURANILAHUE',
    codigo_subdere: 8205,
    region: 8,
  },
  69252400: {
    rut: 69252400,
    nombre: 'CURARREHUE',
    codigo_subdere: 9104,
    region: 9,
  },
  69110300: {
    rut: 69110300,
    nombre: 'CUREPTO',
    codigo_subdere: 7103,
    region: 7,
  },
  69100100: {
    rut: 69100100,
    nombre: 'CURICO',
    codigo_subdere: 7301,
    region: 7,
  },
  69230300: {
    rut: 69230300,
    nombre: 'DALCAHUE',
    codigo_subdere: 10205,
    region: 10,
  },
  69250500: {
    rut: 69250500,
    nombre: 'DIEGO DE ALMAGRO',
    codigo_subdere: 3202,
    region: 3,
  },
  69080600: {
    rut: 69080600,
    nombre: 'DONIHUE',
    codigo_subdere: 6105,
    region: 6,
  },
  69255300: {
    rut: 69255300,
    nombre: 'EL BOSQUE',
    codigo_subdere: 13105,
    region: 13,
  },
  69141700: {
    rut: 69141700,
    nombre: 'EL CARMEN',
    codigo_subdere: 16104,
    region: 16,
  },
  69073000: {
    rut: 69073000,
    nombre: 'EL MONTE',
    codigo_subdere: 13602,
    region: 13,
  },
  69061700: {
    rut: 69061700,
    nombre: 'EL QUISCO',
    codigo_subdere: 5604,
    region: 5,
  },
  69073700: {
    rut: 69073700,
    nombre: 'EL TABO',
    codigo_subdere: 5605,
    region: 5,
  },
  69120200: {
    rut: 69120200,
    nombre: 'EMPEDRADO',
    codigo_subdere: 7104,
    region: 7,
  },
  69180600: {
    rut: 69180600,
    nombre: 'ERCILLA',
    codigo_subdere: 9204,
    region: 9,
  },
  69254300: {
    rut: 69254300,
    nombre: 'ESTACION CENTRAL',
    codigo_subdere: 13106,
    region: 13,
  },
  69150700: {
    rut: 69150700,
    nombre: 'FLORIDA',
    codigo_subdere: 8104,
    region: 8,
  },
  69190900: {
    rut: 69190900,
    nombre: 'FREIRE',
    codigo_subdere: 9105,
    region: 9,
  },
  69030600: {
    rut: 69030600,
    nombre: 'FREIRINA',
    codigo_subdere: 3303,
    region: 3,
  },
  69220400: {
    rut: 69220400,
    nombre: 'FRESIA',
    codigo_subdere: 10104,
    region: 10,
  },
  69220700: {
    rut: 69220700,
    nombre: 'FRUTILLAR',
    codigo_subdere: 10105,
    region: 10,
  },
  69231200: {
    rut: 69231200,
    nombre: 'FUTALEUFU',
    codigo_subdere: 10402,
    region: 10,
  },
  69200700: {
    rut: 69200700,
    nombre: 'FUTRONO',
    codigo_subdere: 14202,
    region: 14,
  },
  69190200: {
    rut: 69190200,
    nombre: 'GALVARINO',
    codigo_subdere: 9106,
    region: 9,
  },
  69250700: {
    rut: 69250700,
    nombre: 'GENERAL LAGOS',
    codigo_subdere: 15202,
    region: 15,
  },
  69191200: {
    rut: 69191200,
    nombre: 'GORBEA',
    codigo_subdere: 9107,
    region: 9,
  },
  69080300: {
    rut: 69080300,
    nombre: 'GRANEROS',
    codigo_subdere: 6106,
    region: 6,
  },
  69253300: {
    rut: 69253300,
    nombre: 'GUAITECAS',
    codigo_subdere: 11203,
    region: 11,
  },
  69060500: {
    rut: 69060500,
    nombre: 'HIJUELAS',
    codigo_subdere: 5503,
    region: 5,
  },
  69252200: {
    rut: 69252200,
    nombre: 'HUALAIHUE',
    codigo_subdere: 10403,
    region: 10,
  },
  69100600: {
    rut: 69100600,
    nombre: 'HUALANE',
    codigo_subdere: 7302,
    region: 7,
  },
  69264400: {
    rut: 69264400,
    nombre: 'HUALPEN',
    codigo_subdere: 8112,
    region: 8,
  },
  69150600: {
    rut: 69150600,
    nombre: 'HUALQUI',
    codigo_subdere: 8105,
    region: 8,
  },
  69010200: {
    rut: 69010200,
    nombre: 'HUARA',
    codigo_subdere: 1404,
    region: 1,
  },
  69030700: {
    rut: 69030700,
    nombre: 'HUASCO',
    codigo_subdere: 3304,
    region: 3,
  },
  69255400: {
    rut: 69255400,
    nombre: 'HUECHURABA',
    codigo_subdere: 13107,
    region: 13,
  },
  69041200: {
    rut: 69041200,
    nombre: 'ILLAPEL',
    codigo_subdere: 4201,
    region: 4,
  },
  69255500: {
    rut: 69255500,
    nombre: 'INDEPENDENCIA',
    codigo_subdere: 13108,
    region: 13,
  },
  69010300: {
    rut: 69010300,
    nombre: 'IQUIQUE',
    codigo_subdere: 1101,
    region: 1,
  },
  69071900: {
    rut: 69071900,
    nombre: 'ISLA DE MAIPO',
    codigo_subdere: 13603,
    region: 13,
  },
  69061800: {
    rut: 69061800,
    nombre: 'ISLA DE PASCUA',
    codigo_subdere: 5201,
    region: 5,
  },
  69252300: {
    rut: 69252300,
    nombre: 'JUAN FERNANDEZ',
    codigo_subdere: 5104,
    region: 5,
  },
  69060300: {
    rut: 69060300,
    nombre: 'LA CALERA',
    codigo_subdere: 5502,
    region: 5,
  },
  69072000: {
    rut: 69072000,
    nombre: 'LA CISTERNA',
    codigo_subdere: 13109,
    region: 13,
  },
  69060200: {
    rut: 69060200,
    nombre: 'LA CRUZ',
    codigo_subdere: 5504,
    region: 5,
  },
  69091400: {
    rut: 69091400,
    nombre: 'LA ESTRELLA',
    codigo_subdere: 6202,
    region: 6,
  },
  69070700: {
    rut: 69070700,
    nombre: 'LA FLORIDA',
    codigo_subdere: 13110,
    region: 13,
  },
  69201100: {
    rut: 69201100,
    nombre: 'LAGO RANCO',
    codigo_subdere: 14203,
    region: 14,
  },
  69253000: {
    rut: 69253000,
    nombre: 'LAGO VERDE',
    codigo_subdere: 11102,
    region: 11,
  },
  69072400: {
    rut: 69072400,
    nombre: 'LA GRANJA',
    codigo_subdere: 13111,
    region: 13,
  },
  69251200: {
    rut: 69251200,
    nombre: 'LAGUNA BLANCA',
    codigo_subdere: 12102,
    region: 12,
  },
  69040200: {
    rut: 69040200,
    nombre: 'LA HIGUERA',
    codigo_subdere: 4104,
    region: 4,
  },
  69170300: {
    rut: 69170300,
    nombre: 'LAJA',
    codigo_subdere: 8304,
    region: 8,
  },
  69050100: {
    rut: 69050100,
    nombre: 'LA LIGUA',
    codigo_subdere: 5401,
    region: 5,
  },
  69071400: {
    rut: 69071400,
    nombre: 'LAMPA',
    codigo_subdere: 13302,
    region: 13,
  },
  69200300: {
    rut: 69200300,
    nombre: 'LANCO',
    codigo_subdere: 14103,
    region: 14,
  },
  69253800: {
    rut: 69253800,
    nombre: 'LA PINTANA',
    codigo_subdere: 13112,
    region: 13,
  },
  69070600: {
    rut: 69070600,
    nombre: 'LA REINA',
    codigo_subdere: 13113,
    region: 13,
  },
  69080800: {
    rut: 69080800,
    nombre: 'LAS CABRAS',
    codigo_subdere: 6107,
    region: 6,
  },
  69070400: {
    rut: 69070400,
    nombre: 'LAS CONDES',
    codigo_subdere: 13114,
    region: 13,
  },
  69040100: {
    rut: 69040100,
    nombre: 'LA SERENA',
    codigo_subdere: 4101,
    region: 4,
  },
  69200800: {
    rut: 69200800,
    nombre: 'LA UNION',
    codigo_subdere: 14201,
    region: 14,
  },
  69190100: {
    rut: 69190100,
    nombre: 'LAUTARO',
    codigo_subdere: 9108,
    region: 9,
  },
  69160300: {
    rut: 69160300,
    nombre: 'LEBU',
    codigo_subdere: 8201,
    region: 8,
  },
  69100500: {
    rut: 69100500,
    nombre: 'LICANTEN',
    codigo_subdere: 7303,
    region: 7,
  },
  69061100: {
    rut: 69061100,
    nombre: 'LIMACHE',
    codigo_subdere: 5802,
    region: 5,
  },
  69130300: {
    rut: 69130300,
    nombre: 'LINARES',
    codigo_subdere: 7401,
    region: 7,
  },
  69091100: {
    rut: 69091100,
    nombre: 'LITUECHE',
    codigo_subdere: 6203,
    region: 6,
  },
  69220300: {
    rut: 69220300,
    nombre: 'LLANQUIHUE',
    codigo_subdere: 10107,
    region: 10,
  },
  69060400: {
    rut: 69060400,
    nombre: 'LLAY LLAY',
    codigo_subdere: 5703,
    region: 5,
  },
  69255200: {
    rut: 69255200,
    nombre: 'LO BARNECHEA',
    codigo_subdere: 13115,
    region: 13,
  },
  69255100: {
    rut: 69255100,
    nombre: 'LO ESPEJO',
    codigo_subdere: 13116,
    region: 13,
  },
  69090500: {
    rut: 69090500,
    nombre: 'LOLOL',
    codigo_subdere: 6304,
    region: 6,
  },
  69191100: {
    rut: 69191100,
    nombre: 'LONCOCHE',
    codigo_subdere: 9109,
    region: 9,
  },
  69130600: {
    rut: 69130600,
    nombre: 'LONGAVI',
    codigo_subdere: 7403,
    region: 7,
  },
  69181100: {
    rut: 69181100,
    nombre: 'LONQUIMAY',
    codigo_subdere: 9205,
    region: 9,
  },
  69254100: {
    rut: 69254100,
    nombre: 'LO PRADO',
    codigo_subdere: 13117,
    region: 13,
  },
  69160400: {
    rut: 69160400,
    nombre: 'LOS ALAMOS',
    codigo_subdere: 8206,
    region: 8,
  },
  69051100: {
    rut: 69051100,
    nombre: 'LOS ANDES',
    codigo_subdere: 5301,
    region: 5,
  },
  69170100: {
    rut: 69170100,
    nombre: 'LOS ANGELES',
    codigo_subdere: 8301,
    region: 8,
  },
  69200600: {
    rut: 69200600,
    nombre: 'LOS LAGOS',
    codigo_subdere: 14104,
    region: 14,
  },
  69220800: {
    rut: 69220800,
    nombre: 'LOS MUERMOS',
    codigo_subdere: 10106,
    region: 10,
  },
  69180300: {
    rut: 69180300,
    nombre: 'LOS SAUCES',
    codigo_subdere: 9206,
    region: 9,
  },
  69041500: {
    rut: 69041500,
    nombre: 'LOS VILOS',
    codigo_subdere: 4203,
    region: 4,
  },
  69151300: {
    rut: 69151300,
    nombre: 'LOTA',
    codigo_subdere: 8106,
    region: 8,
  },
  69180800: {
    rut: 69180800,
    nombre: 'LUMACO',
    codigo_subdere: 9207,
    region: 9,
  },
  69080200: {
    rut: 69080200,
    nombre: 'MACHALI',
    codigo_subdere: 6108,
    region: 6,
  },
  69253700: {
    rut: 69253700,
    nombre: 'MACUL',
    codigo_subdere: 13118,
    region: 13,
  },
  69200500: {
    rut: 69200500,
    nombre: 'MAFIL',
    codigo_subdere: 14105,
    region: 14,
  },
  69070900: {
    rut: 69070900,
    nombre: 'MAIPU',
    codigo_subdere: 13119,
    region: 13,
  },
  69081500: {
    rut: 69081500,
    nombre: 'MALLOA',
    codigo_subdere: 6109,
    region: 6,
  },
  69091300: {
    rut: 69091300,
    nombre: 'MARCHIHUE',
    codigo_subdere: 6204,
    region: 6,
  },
  69253600: {
    rut: 69253600,
    nombre: 'MARIA ELENA',
    codigo_subdere: 2302,
    region: 2,
  },
  69073300: {
    rut: 69073300,
    nombre: 'MARIA PINTO',
    codigo_subdere: 13504,
    region: 13,
  },
  69200400: {
    rut: 69200400,
    nombre: 'MARIQUINA',
    codigo_subdere: 14106,
    region: 14,
  },
  69110900: {
    rut: 69110900,
    nombre: 'MAULE',
    codigo_subdere: 7105,
    region: 7,
  },
  69220500: {
    rut: 69220500,
    nombre: 'MAULLIN',
    codigo_subdere: 10108,
    region: 10,
  },
  69020400: {
    rut: 69020400,
    nombre: 'MEJILLONES',
    codigo_subdere: 2102,
    region: 2,
  },
  70766500: {
    rut: 70766500,
    nombre: 'MELIPEUCO',
    codigo_subdere: 9110,
    region: 9,
  },
  69072900: {
    rut: 69072900,
    nombre: 'MELIPILLA',
    codigo_subdere: 13501,
    region: 13,
  },
  69110100: {
    rut: 69110100,
    nombre: 'MOLINA',
    codigo_subdere: 7304,
    region: 7,
  },
  69040800: {
    rut: 69040800,
    nombre: 'MONTE PATRIA',
    codigo_subdere: 4303,
    region: 4,
  },
  69080500: {
    rut: 69080500,
    nombre: 'MOSTAZAL',
    codigo_subdere: 6110,
    region: 6,
  },
  69170500: {
    rut: 69170500,
    nombre: 'MULCHEN',
    codigo_subdere: 8305,
    region: 8,
  },
  69170700: {
    rut: 69170700,
    nombre: 'NACIMIENTO',
    codigo_subdere: 8306,
    region: 8,
  },
  69090400: {
    rut: 69090400,
    nombre: 'NANCAGUA',
    codigo_subdere: 6305,
    region: 6,
  },
  69073800: {
    rut: 69073800,
    nombre: 'NAVIDAD',
    codigo_subdere: 6205,
    region: 6,
  },
  69170800: {
    rut: 69170800,
    nombre: 'NEGRETE',
    codigo_subdere: 8307,
    region: 8,
  },
  69140300: {
    rut: 69140300,
    nombre: 'NINHUE',
    codigo_subdere: 16204,
    region: 16,
  },
  69140600: {
    rut: 69140600,
    nombre: 'NIQUEN',
    codigo_subdere: 16303,
    region: 16,
  },
  69060600: {
    rut: 69060600,
    nombre: 'NOGALES',
    codigo_subdere: 5506,
    region: 5,
  },
  69190400: {
    rut: 69190400,
    nombre: 'NUEVA IMPERIAL',
    codigo_subdere: 9111,
    region: 9,
  },
  69070500: {
    rut: 69070500,
    nombre: 'NUNOA',
    codigo_subdere: 13120,
    region: 13,
  },
  69253500: {
    rut: 69253500,
    nombre: "O'HIGGINS",
    codigo_subdere: 11302,
    region: 11,
  },
  69081400: {
    rut: 69081400,
    nombre: 'OLIVAR',
    codigo_subdere: 6111,
    region: 6,
  },
  69252600: {
    rut: 69252600,
    nombre: 'OLLAG\u00dcE',
    codigo_subdere: 2202,
    region: 2,
  },
  69061200: {
    rut: 69061200,
    nombre: 'OLMUE',
    codigo_subdere: 5803,
    region: 5,
  },
  69210100: {
    rut: 69210100,
    nombre: 'OSORNO',
    codigo_subdere: 10301,
    region: 10,
  },
  69040700: {
    rut: 69040700,
    nombre: 'OVALLE',
    codigo_subdere: 4301,
    region: 4,
  },
  69261400: {
    rut: 69261400,
    nombre: 'PADRE HURTADO',
    codigo_subdere: 13604,
    region: 13,
  },
  61955000: {
    rut: 61955000,
    nombre: 'PADRE LAS CASAS',
    codigo_subdere: 9112,
    region: 9,
  },
  69040600: {
    rut: 69040600,
    nombre: 'PAIHUANO',
    codigo_subdere: 4105,
    region: 4,
  },
  69200900: {
    rut: 69200900,
    nombre: 'PAILLACO',
    codigo_subdere: 14107,
    region: 14,
  },
  69072600: {
    rut: 69072600,
    nombre: 'PAINE',
    codigo_subdere: 13404,
    region: 13,
  },
  69231300: {
    rut: 69231300,
    nombre: 'PALENA',
    codigo_subdere: 10404,
    region: 10,
  },
  69091000: {
    rut: 69091000,
    nombre: 'PALMILLA',
    codigo_subdere: 6306,
    region: 6,
  },
  69201200: {
    rut: 69201200,
    nombre: 'PANGUIPULLI',
    codigo_subdere: 14108,
    region: 14,
  },
  69050800: {
    rut: 69050800,
    nombre: 'PANQUEHUE',
    codigo_subdere: 5704,
    region: 5,
  },
  69050300: {
    rut: 69050300,
    nombre: 'PAPUDO',
    codigo_subdere: 5403,
    region: 5,
  },
  69090900: {
    rut: 69090900,
    nombre: 'PAREDONES',
    codigo_subdere: 6206,
    region: 6,
  },
  69130700: {
    rut: 69130700,
    nombre: 'PARRAL',
    codigo_subdere: 7404,
    region: 7,
  },
  69254900: {
    rut: 69254900,
    nombre: 'PEDRO AGUIRRE CERDA',
    codigo_subdere: 13121,
    region: 13,
  },
  69110600: {
    rut: 69110600,
    nombre: 'PELARCO',
    codigo_subdere: 7106,
    region: 7,
  },
  69252700: {
    rut: 69252700,
    nombre: 'PELLUHUE',
    codigo_subdere: 7203,
    region: 7,
  },
  69141600: {
    rut: 69141600,
    nombre: 'PEMUCO',
    codigo_subdere: 16105,
    region: 16,
  },
  69071700: {
    rut: 69071700,
    nombre: 'PENAFLOR',
    codigo_subdere: 13605,
    region: 13,
  },
  69254000: {
    rut: 69254000,
    nombre: 'PENALOLEN',
    codigo_subdere: 13122,
    region: 13,
  },
  69110800: {
    rut: 69110800,
    nombre: 'PENCAHUE',
    codigo_subdere: 7107,
    region: 7,
  },
  69150500: {
    rut: 69150500,
    nombre: 'PENCO',
    codigo_subdere: 8107,
    region: 8,
  },
  69091500: {
    rut: 69091500,
    nombre: 'PERALILLO',
    codigo_subdere: 6307,
    region: 6,
  },
  69190300: {
    rut: 69190300,
    nombre: 'PERQUENCO',
    codigo_subdere: 9113,
    region: 9,
  },
  69050500: {
    rut: 69050500,
    nombre: 'PETORCA',
    codigo_subdere: 5404,
    region: 5,
  },
  69080900: {
    rut: 69080900,
    nombre: 'PEUMO',
    codigo_subdere: 6112,
    region: 6,
  },
  69010400: {
    rut: 69010400,
    nombre: 'PICA',
    codigo_subdere: 1405,
    region: 1,
  },
  69081100: {
    rut: 69081100,
    nombre: 'PICHIDEGUA',
    codigo_subdere: 6113,
    region: 6,
  },
  69091200: {
    rut: 69091200,
    nombre: 'PICHILEMU',
    codigo_subdere: 6201,
    region: 6,
  },
  69141000: {
    rut: 69141000,
    nombre: 'PINTO',
    codigo_subdere: 16106,
    region: 16,
  },
  69072200: {
    rut: 69072200,
    nombre: 'PIRQUE',
    codigo_subdere: 13202,
    region: 13,
  },
  69191300: {
    rut: 69191300,
    nombre: 'PITRUFQUEN',
    codigo_subdere: 9114,
    region: 9,
  },
  69090200: {
    rut: 69090200,
    nombre: 'PLACILLA',
    codigo_subdere: 6308,
    region: 6,
  },
  69140200: {
    rut: 69140200,
    nombre: 'PORTEZUELO',
    codigo_subdere: 16205,
    region: 16,
  },
  69250300: {
    rut: 69250300,
    nombre: 'PORVENIR',
    codigo_subdere: 12301,
    region: 12,
  },
  83017500: {
    rut: 83017500,
    nombre: 'POZO ALMONTE',
    codigo_subdere: 1401,
    region: 1,
  },
  69251300: {
    rut: 69251300,
    nombre: 'PRIMAVERA',
    codigo_subdere: 12302,
    region: 12,
  },
  69070300: {
    rut: 69070300,
    nombre: 'PROVIDENCIA',
    codigo_subdere: 13123,
    region: 13,
  },
  69060800: {
    rut: 69060800,
    nombre: 'PUCHUNCAVI',
    codigo_subdere: 5105,
    region: 5,
  },
  69191600: {
    rut: 69191600,
    nombre: 'PUCON',
    codigo_subdere: 9115,
    region: 9,
  },
  69071100: {
    rut: 69071100,
    nombre: 'PUDAHUEL',
    codigo_subdere: 13124,
    region: 13,
  },
  69072100: {
    rut: 69072100,
    nombre: 'PUENTE ALTO',
    codigo_subdere: 13201,
    region: 13,
  },
  69220100: {
    rut: 69220100,
    nombre: 'PUERTO MONTT',
    codigo_subdere: 10101,
    region: 10,
  },
  69250100: {
    rut: 69250100,
    nombre: 'NATALES',
    codigo_subdere: 12401,
    region: 12,
  },
  69210400: {
    rut: 69210400,
    nombre: 'PUERTO OCTAY',
    codigo_subdere: 10302,
    region: 10,
  },
  69220200: {
    rut: 69220200,
    nombre: 'PUERTO VARAS',
    codigo_subdere: 10109,
    region: 10,
  },
  69090800: {
    rut: 69090800,
    nombre: 'PUMANQUE',
    codigo_subdere: 6309,
    region: 6,
  },
  69040900: {
    rut: 69040900,
    nombre: 'PUNITAQUI',
    codigo_subdere: 4304,
    region: 4,
  },
  69250200: {
    rut: 69250200,
    nombre: 'PUNTA ARENAS',
    codigo_subdere: 12101,
    region: 12,
  },
  69230800: {
    rut: 69230800,
    nombre: 'PUQUELDON',
    codigo_subdere: 10206,
    region: 10,
  },
  69180200: {
    rut: 69180200,
    nombre: 'PUREN',
    codigo_subdere: 9208,
    region: 9,
  },
  69210500: {
    rut: 69210500,
    nombre: 'PURRANQUE',
    codigo_subdere: 10303,
    region: 10,
  },
  69050700: {
    rut: 69050700,
    nombre: 'PUTAENDO',
    codigo_subdere: 5705,
    region: 5,
  },
  69250800: {
    rut: 69250800,
    nombre: 'PUTRE',
    codigo_subdere: 15201,
    region: 15,
  },
  69210600: {
    rut: 69210600,
    nombre: 'PUYEHUE',
    codigo_subdere: 10304,
    region: 10,
  },
  69230600: {
    rut: 69230600,
    nombre: 'QUEILEN',
    codigo_subdere: 10207,
    region: 10,
  },
  69230700: {
    rut: 69230700,
    nombre: 'QUELLON',
    codigo_subdere: 10208,
    region: 10,
  },
  69230200: {
    rut: 69230200,
    nombre: 'QUEMCHI',
    codigo_subdere: 10209,
    region: 10,
  },
  69172600: {
    rut: 69172600,
    nombre: 'QUILACO',
    codigo_subdere: 8308,
    region: 8,
  },
  69071300: {
    rut: 69071300,
    nombre: 'QUILICURA',
    codigo_subdere: 13125,
    region: 13,
  },
  69170400: {
    rut: 69170400,
    nombre: 'QUILLECO',
    codigo_subdere: 8309,
    region: 8,
  },
  69141400: {
    rut: 69141400,
    nombre: 'QUILLON',
    codigo_subdere: 16107,
    region: 16,
  },
  69060100: {
    rut: 69060100,
    nombre: 'QUILLOTA',
    codigo_subdere: 5501,
    region: 5,
  },
  69061300: {
    rut: 69061300,
    nombre: 'QUILPUE',
    codigo_subdere: 5801,
    region: 5,
  },
  69230900: {
    rut: 69230900,
    nombre: 'QUINCHAO',
    codigo_subdere: 10210,
    region: 10,
  },
  69081700: {
    rut: 69081700,
    nombre: 'QUINTA DE TILCOCO',
    codigo_subdere: 6114,
    region: 6,
  },
  69071000: {
    rut: 69071000,
    nombre: 'QUINTA NORMAL',
    codigo_subdere: 13126,
    region: 13,
  },
  69060700: {
    rut: 69060700,
    nombre: 'QUINTERO',
    codigo_subdere: 5107,
    region: 5,
  },
  69140100: {
    rut: 69140100,
    nombre: 'QUIRIHUE',
    codigo_subdere: 16201,
    region: 16,
  },
  69080100: {
    rut: 69080100,
    nombre: 'RANCAGUA',
    codigo_subdere: 6101,
    region: 6,
  },
  69150300: {
    rut: 69150300,
    nombre: 'RANQUIL',
    codigo_subdere: 16206,
    region: 16,
  },
  69100400: {
    rut: 69100400,
    nombre: 'RAUCO',
    codigo_subdere: 7305,
    region: 7,
  },
  69254800: {
    rut: 69254800,
    nombre: 'RECOLETA',
    codigo_subdere: 13127,
    region: 13,
  },
  69180400: {
    rut: 69180400,
    nombre: 'RENAICO',
    codigo_subdere: 9209,
    region: 9,
  },
  69071200: {
    rut: 69071200,
    nombre: 'RENCA',
    codigo_subdere: 13128,
    region: 13,
  },
  69081200: {
    rut: 69081200,
    nombre: 'RENGO',
    codigo_subdere: 6115,
    region: 6,
  },
  69081300: {
    rut: 69081300,
    nombre: 'REQUINOA',
    codigo_subdere: 6116,
    region: 6,
  },
  69130800: {
    rut: 69130800,
    nombre: 'RETIRO',
    codigo_subdere: 7405,
    region: 7,
  },
  69051300: {
    rut: 69051300,
    nombre: 'RINCONADA',
    codigo_subdere: 5303,
    region: 5,
  },
  69201000: {
    rut: 69201000,
    nombre: 'R\u00cdO BUENO',
    codigo_subdere: 14204,
    region: 14,
  },
  69110700: {
    rut: 69110700,
    nombre: 'RIO CLARO',
    codigo_subdere: 7108,
    region: 7,
  },
  69041000: {
    rut: 69041000,
    nombre: 'RIO HURTADO',
    codigo_subdere: 4305,
    region: 4,
  },
  69253100: {
    rut: 69253100,
    nombre: 'RIO IBANEZ',
    codigo_subdere: 11402,
    region: 11,
  },
  69210300: {
    rut: 69210300,
    nombre: 'RIO NEGRO',
    codigo_subdere: 10305,
    region: 10,
  },
  69251400: {
    rut: 69251400,
    nombre: 'RIO VERDE',
    codigo_subdere: 12103,
    region: 12,
  },
  69100200: {
    rut: 69100200,
    nombre: 'ROMERAL',
    codigo_subdere: 7306,
    region: 7,
  },
  69190600: {
    rut: 69190600,
    nombre: 'SAAVEDRA',
    codigo_subdere: 9116,
    region: 9,
  },
  69110200: {
    rut: 69110200,
    nombre: 'SAGRADA FAMILIA',
    codigo_subdere: 7307,
    region: 7,
  },
  69041400: {
    rut: 69041400,
    nombre: 'SALAMANCA',
    codigo_subdere: 4204,
    region: 4,
  },
  69073400: {
    rut: 69073400,
    nombre: 'SAN ANTONIO',
    codigo_subdere: 5601,
    region: 5,
  },
  69072700: {
    rut: 69072700,
    nombre: 'SAN BERNARDO',
    codigo_subdere: 13401,
    region: 13,
  },
  69140500: {
    rut: 69140500,
    nombre: 'SAN CARLOS',
    codigo_subdere: 16301,
    region: 16,
  },
  69110500: {
    rut: 69110500,
    nombre: 'SAN CLEMENTE',
    codigo_subdere: 7109,
    region: 7,
  },
  69051400: {
    rut: 69051400,
    nombre: 'SAN ESTEBAN',
    codigo_subdere: 5304,
    region: 5,
  },
  69140700: {
    rut: 69140700,
    nombre: 'SAN FABIAN',
    codigo_subdere: 16304,
    region: 16,
  },
  69050600: {
    rut: 69050600,
    nombre: 'SAN FELIPE',
    codigo_subdere: 5701,
    region: 5,
  },
  69090100: {
    rut: 69090100,
    nombre: 'SAN FERNANDO',
    codigo_subdere: 6301,
    region: 6,
  },
  69251500: {
    rut: 69251500,
    nombre: 'SAN GREGORIO',
    codigo_subdere: 12104,
    region: 12,
  },
  69141300: {
    rut: 69141300,
    nombre: 'SAN IGNACIO',
    codigo_subdere: 16108,
    region: 16,
  },
  69130100: {
    rut: 69130100,
    nombre: 'SAN JAVIER',
    codigo_subdere: 7406,
    region: 7,
  },
  69254600: {
    rut: 69254600,
    nombre: 'SAN JOAQUIN',
    codigo_subdere: 13129,
    region: 13,
  },
  69072300: {
    rut: 69072300,
    nombre: 'SAN JOSE DE MAIPO',
    codigo_subdere: 13203,
    region: 13,
  },
  69251800: {
    rut: 69251800,
    nombre: 'SAN JUAN DE LA COSTA',
    codigo_subdere: 10306,
    region: 10,
  },
  69070800: {
    rut: 69070800,
    nombre: 'SAN MIGUEL',
    codigo_subdere: 13130,
    region: 13,
  },
  69140800: {
    rut: 69140800,
    nombre: 'SAN NICOLAS',
    codigo_subdere: 16305,
    region: 16,
  },
  69210200: {
    rut: 69210200,
    nombre: 'SAN PABLO',
    codigo_subdere: 10307,
    region: 10,
  },
  69073100: {
    rut: 69073100,
    nombre: 'SAN PEDRO',
    codigo_subdere: 13505,
    region: 13,
  },
  69252500: {
    rut: 69252500,
    nombre: 'SAN PEDRO DE ATACAMA',
    codigo_subdere: 2203,
    region: 2,
  },
  69264800: {
    rut: 69264800,
    nombre: 'SAN PEDRO DE LA PAZ',
    codigo_subdere: 8108,
    region: 8,
  },
  69264500: {
    rut: 69264500,
    nombre: 'SAN RAFAEL',
    codigo_subdere: 7110,
    region: 7,
  },
  69253900: {
    rut: 69253900,
    nombre: 'SAN RAMON',
    codigo_subdere: 13131,
    region: 13,
  },
  69151100: {
    rut: 69151100,
    nombre: 'SAN ROSENDO',
    codigo_subdere: 8310,
    region: 8,
  },
  69170200: {
    rut: 69170200,
    nombre: 'SANTA BARBARA',
    codigo_subdere: 8311,
    region: 8,
  },
  69090600: {
    rut: 69090600,
    nombre: 'SANTA CRUZ',
    codigo_subdere: 6310,
    region: 6,
  },
  69151400: {
    rut: 69151400,
    nombre: 'SANTA JUANA',
    codigo_subdere: 8109,
    region: 8,
  },
  69051000: {
    rut: 69051000,
    nombre: 'SANTA MARIA',
    codigo_subdere: 5706,
    region: 5,
  },
  69070100: {
    rut: 69070100,
    nombre: 'SANTIAGO',
    codigo_subdere: 13101,
    region: 13,
  },
  69073500: {
    rut: 69073500,
    nombre: 'SANTO DOMINGO',
    codigo_subdere: 5606,
    region: 5,
  },
  69081000: {
    rut: 69081000,
    nombre: 'SAN VICENTE',
    codigo_subdere: 6117,
    region: 6,
  },
  69253200: {
    rut: 69253200,
    nombre: 'SIERRA GORDA',
    codigo_subdere: 2103,
    region: 2,
  },
  69071800: {
    rut: 69071800,
    nombre: 'TALAGANTE',
    codigo_subdere: 13601,
    region: 13,
  },
  69110400: {
    rut: 69110400,
    nombre: 'TALCA',
    codigo_subdere: 7101,
    region: 7,
  },
  69150800: {
    rut: 69150800,
    nombre: 'TALCAHUANO',
    codigo_subdere: 8110,
    region: 8,
  },
  69020500: {
    rut: 69020500,
    nombre: 'TALTAL',
    codigo_subdere: 2104,
    region: 2,
  },
  69190700: {
    rut: 69190700,
    nombre: 'TEMUCO',
    codigo_subdere: 9101,
    region: 9,
  },
  69100300: {
    rut: 69100300,
    nombre: 'TENO',
    codigo_subdere: 7308,
    region: 7,
  },
  69252100: {
    rut: 69252100,
    nombre: 'TEODORO SCHMIDT',
    codigo_subdere: 9117,
    region: 9,
  },
  69030400: {
    rut: 69030400,
    nombre: 'TIERRA AMARILLA',
    codigo_subdere: 3103,
    region: 3,
  },
  69071600: {
    rut: 69071600,
    nombre: 'TIL TIL',
    codigo_subdere: 13303,
    region: 13,
  },
  69251600: {
    rut: 69251600,
    nombre: 'TIMAUKEL',
    codigo_subdere: 12303,
    region: 12,
  },
  69160700: {
    rut: 69160700,
    nombre: 'TIRUA',
    codigo_subdere: 8207,
    region: 8,
  },
  69020100: {
    rut: 69020100,
    nombre: 'TOCOPILLA',
    codigo_subdere: 2301,
    region: 2,
  },
  69191400: {
    rut: 69191400,
    nombre: 'TOLTEN',
    codigo_subdere: 9118,
    region: 9,
  },
  69150100: {
    rut: 69150100,
    nombre: 'TOME',
    codigo_subdere: 8111,
    region: 8,
  },
  69251700: {
    rut: 69251700,
    nombre: 'TORRES DEL PAINE',
    codigo_subdere: 12402,
    region: 12,
  },
  69253400: {
    rut: 69253400,
    nombre: 'TORTEL',
    codigo_subdere: 11303,
    region: 11,
  },
  69180700: {
    rut: 69180700,
    nombre: 'TRAIGUEN',
    codigo_subdere: 9210,
    region: 9,
  },
  69250602: {
    rut: 69250602,
    nombre: 'TREHUACO',
    codigo_subdere: 16207,
    region: 16,
  },
  69141800: {
    rut: 69141800,
    nombre: 'TUCAPEL',
    codigo_subdere: 8312,
    region: 8,
  },
  69200100: {
    rut: 69200100,
    nombre: 'VALDIVIA',
    codigo_subdere: 14101,
    region: 14,
  },
  69030500: {
    rut: 69030500,
    nombre: 'VALLENAR',
    codigo_subdere: 3301,
    region: 3,
  },
  69060900: {
    rut: 69060900,
    nombre: 'VALPARAISO',
    codigo_subdere: 5101,
    region: 5,
  },
  69100700: {
    rut: 69100700,
    nombre: 'VICHUQUEN',
    codigo_subdere: 7309,
    region: 7,
  },
  69180900: {
    rut: 69180900,
    nombre: 'VICTORIA',
    codigo_subdere: 9211,
    region: 9,
  },
  69040500: {
    rut: 69040500,
    nombre: 'VICUNA',
    codigo_subdere: 4106,
    region: 4,
  },
  69190800: {
    rut: 69190800,
    nombre: 'VILCUN',
    codigo_subdere: 9119,
    region: 9,
  },
  69130200: {
    rut: 69130200,
    nombre: 'VILLA ALEGRE',
    codigo_subdere: 7407,
    region: 7,
  },
  69061500: {
    rut: 69061500,
    nombre: 'VILLA ALEMANA',
    codigo_subdere: 5804,
    region: 5,
  },
  69191500: {
    rut: 69191500,
    nombre: 'VILLARRICA',
    codigo_subdere: 9120,
    region: 9,
  },
  69061000: {
    rut: 69061000,
    nombre: 'VINA DEL MAR',
    codigo_subdere: 5109,
    region: 5,
  },
  69255600: {
    rut: 69255600,
    nombre: 'VITACURA',
    codigo_subdere: 13132,
    region: 13,
  },
  69130400: {
    rut: 69130400,
    nombre: 'YERBAS BUENAS',
    codigo_subdere: 7408,
    region: 7,
  },
  69150900: {
    rut: 69150900,
    nombre: 'YUMBEL',
    codigo_subdere: 8313,
    region: 8,
  },
  69141500: {
    rut: 69141500,
    nombre: 'YUNGAY',
    codigo_subdere: 16109,
    region: 16,
  },
  69050400: {
    rut: 69050400,
    nombre: 'ZAPALLAR',
    codigo_subdere: 5405,
    region: 5,
  },
}
